import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import './result-form.scss'
import { Button } from '@mui/material';
import React from 'react';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 7,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#FFFFFFAD',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 7,
        backgroundColor: '#E1D8CA;',
    },
}));

export interface ResultFormProps {
    name: string;
    onSubmitted: () => void;
}

export default function ResultForm(props: ResultFormProps) {
    return (
        <div className={'result-form'}>
            <div className={'result-form-title'}>Skin Report</div>
            <div className={'result-form-progress'}>
                <BorderLinearProgress variant="determinate" value={100.0}/>
            </div>
            <div className={'result-form-description'}>Your skin report has been completed.</div>

            <div className={'result-form-content'}>
                <p>Submit your skin report and check the consultation for your skin type.</p>
                <p><br/></p>
                <p>Our SYRS skin consultant will analyze your skin</p>
                <p>and provide you with the Sincere Kit full of authenticity.</p>

                <div className={'result-form-content-notice'}>Experience improved skin with meticulous care.</div>
            </div>

            <div className={'result-form-action-bar'}>
                <div className={'space'}></div>
                <Button className={'right'} onClick={props.onSubmitted}>Submit</Button>
            </div>

        </div>
    );
}
