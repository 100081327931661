import React, { ReactElement, useState } from 'react';

import './survey-page.scss';
import SurveyForm from './survey-form/survey-form';
import { Question } from '../../models/question';
import PrivacyForm from './privacy-form/privacy-form';
import ResultForm from './result-form/result-form';
import SurveyHeader from '../../components/survey-header/survey-header';
import { CreatingAnswer, Score } from '../../models/answer';
import AnswerService from '../../services/answer-service';
import { useLocation, useNavigate } from 'react-router-dom';
import KakaoTalkService from '../../services/kakaotalk-service';


export default function SurveyPage(): ReactElement {
    const location = useLocation();
    const navigate = useNavigate();
    const [ step, setStep ] = useState<number>(0);
    const [ answer, setAnswer ] = useState<CreatingAnswer>({
        name: undefined,
        phoneNumber: undefined,
        birthDay: new Date(),
        createdDate: new Date(),
        choices: [],
        scores: [],
        resultType: undefined
    } as CreatingAnswer);

    const queryParams = new URLSearchParams(location.search);
    const from = queryParams.get('from');

    function renderItem(items: any[]): ReactElement {
        return items[step];
    }

    function onPrivacyInfoEntered(name: string, telNumber: string, birthDay: Date) {
        setAnswer({
            ...answer,
            name,
            phoneNumber: telNumber,
            birthDay
        });
        setStep(step + 1);
    }

    function onQuestionAnswered(questionIndex: number, answerIndex: number) {
        const a = {
            ...answer
        };
        a.choices[questionIndex] = answerIndex;
        setAnswer(a);
        setStep(step + 1)
    }

    function onSubmitted() {
        const scoreMapping = [ 4, 3, 2, 1 ];
        const t = answer.choices.map(c => scoreMapping[c]);
        const scores: Score[] = [
            { type: 'A', score: t[0] + t[1] },
            { type: 'B', score: t[2] + t[3] },
            { type: 'C', score: t[4] + t[5] },
            { type: 'D', score: t[6] + t[7] },
            { type: 'E', score: t[8] + t[9] }
        ];

        const sortedScores: Score[] = scores.sort((a: Score, b: Score): number => {
            const r = b.score - a.score;
            if (r !== 0) {
                return r;
            }

            return a.type.charCodeAt(0) - b.type.charCodeAt(0);
        });

        const result: CreatingAnswer = {
            ...answer,
            scores: sortedScores,
            resultType: sortedScores[0].type
        };

        const service = new AnswerService();
        service.write(result).then(() => {
            navigate(`/result/${result.resultType}` + (from ? `?from=${from}` : ''));
        });

        const kakaoTalkService = new KakaoTalkService();
        kakaoTalkService.sendMessage(answer.phoneNumber!, answer.name!, sortedScores[0].type).then(a => {
        });
    }

    const questions: Question[] = [
        {
            title: 'Q1',
            description: '<p>What makes the skin redden?</p>',
            answers: [
                'Reddens easily to changes or slight irritants, lasting long.',
                'Quickly reddens to minor irritants, but returns to normal soon.',
                'Only reddens to strong irritants like scratches.',
                'Rarely experiences skin reddening.'
            ]
        },
        {
            title: 'Q2',
            description: '<p>What is your skin texture like?</p>',
            answers: [
                'Visibly rough.',
                'Rough to the touch.',
                'Neither rough visually nor by touch.',
                'Smooth and sleek.'
            ]
        },
        {
            title: 'Q3',
            description: '<p>How is your skin immediately after cleansing?</p>',
            answers: [
                'Tight all over.',
                'Tight in areas like cheeks, forehead.',
                'Looks okay, feels tight inside.',
                'No tightness anywhere.',
            ]
        },
        {
            title: 'Q4',
            description: '<p>State of your skin after applying basic skincare?</p>',
            answers: [
                'Remains dry post-application.',
                'Seems okay but soon feels dry.',
                'Moisture stays 2-3 hours.',
                'Moist and soft all day.',
            ]
        },
        {
            title: 'Q5',
            description: '<p>Facial wrinkle status when frowning or smiling?</p>',
            answers: [
                'Wrinkles all over face.',
                'Wrinkles near eyes, mouth with movement.',
                'Noticeable in parts, light wrinkles.',
                'Almost no wrinkles.',
            ]
        },
        {
            title: 'Q6',
            description: '<p>Facial wrinkle status when face is at rest?</p>',
            answers: [
                'Wrinkles persist all over.',
                'Wrinkles stay near eyes, mouth.',
                'Blurry wrinkles fade quickly.',
                'Smooth, no trace.',
            ]
        },
        {
            title: 'Q7',
            description: '<p>What is your everyday skin condition?</p>',
            answers: [
                'Tight inside, greasy outside.',
                'Feels dry, oily on the surface.',
                'Slightly dry.',
                'Not dry or oily.',
            ]
        },
        {
            title: 'Q8',
            description: '<p>How often do you experience breakouts?</p>',
            answers: [
                'Frequently, every few days.',
                'Every 2-3 weeks.',
                'Every few months or years.',
                'Almost never.',
            ]
        },
        {
            title: 'Q9',
            description: '<p>Frequency of spots like freckles or dark spots?</p>',
            answers: [
                'Widespread, continuous.',
                'Periodic, not lasting.',
                'Rare, every few years.',
                'Almost none.',
            ]
        },
        {
            title: 'Q10',
            description: '<p>Duration of brown marks from acne or injuries?</p>',
            answers: [
                '6 months to over a year',
                '1 to 6 months',
                'Within 4 weeks',
                'None.',
            ]
        },                
    ];

    const items = [
        <PrivacyForm
            name={answer.name}
            phoneNumber={answer.phoneNumber}
            birthDay={answer.birthDay}
            onEntered={onPrivacyInfoEntered}/>,
        ...(questions.map((q, qi) =>
            <SurveyForm question={q}
                        selected={answer.choices[qi]}
                        onAnswered={(ai) => onQuestionAnswered(qi, ai)}
                        onCanceled={() => setStep(step - 1)}
                        maxStepCount={questions.length + 1}
                        currentStep={qi + 1}/>
        )),
        <ResultForm name={answer.name!} onSubmitted={onSubmitted}/>
    ]

    return (
        <div className={'survey-page'}>
            <SurveyHeader closable={true} onClosed={() => {
                navigate(`/${from}`);
            }}/>
            <div className={'survey-page-content'}>{renderItem(items)}</div>
        </div>
    );
}
