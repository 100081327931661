import { ReactElement, useEffect, useState } from 'react';
import TextInputField from '../../../components/text-input-field/text-input-field';
import BirthDaySelector from '../../../components/birth-day-selector/birth-day-selector';

import './privacy-form.scss';
import { Button } from '@mui/material';
import KakaoTalkService from '../../../services/kakaotalk-service';

interface PrivacyFormProps {
    name?: string;
    phoneNumber?: string;
    birthDay?: Date;
    onEntered: (name: string, telNumber: string, birthDay: Date) => void;
}

export default function PrivacyForm(props: PrivacyFormProps): ReactElement {
    const [ disabled, setDisabled ] = useState<boolean>(false);
    const [ name, setName ] = useState<string | undefined>(props.name);
    const [ phoneNumber, setPhoneNumber ] = useState<string | undefined>(props.phoneNumber);
    const [ birthDay, setBirthDay ] = useState<Date | undefined>(props.birthDay);

    // function onBirthdaySelected(d: Date) {
    //     console.log(d);
    // }

    useEffect(() => {
        setName(props.name);
        setPhoneNumber(props.phoneNumber);
        setBirthDay(props.birthDay);
    }, [ props.birthDay, props.name, props.phoneNumber ]);

    useEffect(() => {
        setDisabled(!name || !phoneNumber || !birthDay);
    }, [ name, phoneNumber, birthDay ]);

    function onNext() {
        const service = new KakaoTalkService();
        // service.sendMessage('010-3380-0842', 'adad', 'a').then(() => {});
        props.onEntered(name!, phoneNumber!, birthDay!);
    }

    return (
        <div className={'privacy-form'}>
            <div className={'privacy-form-content'}>
                <div className={'privacy-form-content-title'}>One-on-one skin consultation</div>
                <div className={'privacy-form-content-description'}>
                    <div className={'privacy-form-content-description-text'}>
                        <p>Experience SYRS one-on-one skin consultation for fundamental improvements to your skin problems. After the consultation, you will receive the Sincere Kit containing five of the most effective SYRS products.</p>
                    </div>

                    <div className={'privacy-form-content-description-additional'}>
                        <p>* Before the diagnosis, we ask for your name, email, and date of birth to share the results with you via your email and to provide continuous aftercare. It will not be used for any other purposes.</p>
                    </div>
                </div>

                <div className={'privacy-form-content-description-form'}>
                    <TextInputField label={'Name'}
                                    value={props.name}
                                    onEntered={(text: string) => setName(text)}/>

                    <TextInputField label={'E-Mail'}
                                    type={'tel'}
                                    value={props.phoneNumber}
                                    onEntered={(text: string) => setPhoneNumber(text)}/>

                    <BirthDaySelector label={'Date of Birth'}
                                      value={props.birthDay ?? undefined}
                                      onSelected={(birthDay) => setBirthDay(birthDay)}/>
                </div>
            </div>
            <div className={'privacy-form-action-bar'}>
                <Button onClick={onNext} disabled={disabled}>Start Survey</Button>
            </div>
        </div>
    );
}
