import { ReactElement } from 'react';
import './product-layout.scss'
import Product from '../../models/product';
import Menu from '../menu/menu';
import TextBlock from '../text-block/text-block';
import { ImageParagraph, TextParagraph } from '../../models/paragraph';
import ImageBlock from '../image-block/image-block';
import SubTextBlock from '../sub-text-block/sub-text-block';

export interface ProductLayoutProps {
    data: Product
    isConsultingMode: boolean
}

export default function ProductLayout(props: ProductLayoutProps): ReactElement {
    const classNames: string[] = [ 'description1', 'description2', 'description3' ];

    return (
        <div className={'product-layout'} style={{ backgroundImage: `url(${props.data.backgroundUrl})` }}>
            <Menu isConsultingMode={props.isConsultingMode}/>
            <div className={'title'} dangerouslySetInnerHTML={{ __html: props.data.title }}/>
            <div className={'product-layout-content'}>
                {
                    props.data.paragraphs.map((x, i) => {
                        if (x.type === 'text') {
                            const t = x as TextParagraph;
                            return (
                                <>
                                    <TextBlock title={t.title} content={t.content} className={classNames[i]}/>
                                    <div className={'spacer'}></div>
                                </>

                            );
                        } else if (x.type === 'subText') {
                            return <SubTextBlock paragraph={x as TextParagraph} className={classNames[i]}/>;
                        } else if (x.type === 'image') {
                            return <ImageBlock paragraph={x as ImageParagraph}/>;
                        } else {
                            return <div>unknown type</div>
                        }
                    })
                }

                <div className={'notice'}>
                    If you need information about {props.data.name}, please ask a staff member.
                </div>

            </div>
        </div>
    );
}
